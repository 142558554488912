import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import { apiGetAuth, apiPost } from "../../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../../Util/EndPoint";
import Loader from "../global/Loader";

import UserImg from "../../assets/images/user.jpg";
import PaginatedData from "../global/PaginatedData";


const AssignStockRider = ({ showAddPro, hideAddPro, selectedItem }) => {

  const sendNotification = () => {
    setLoader(true);

    apiPost(
      ENDPOINTS.RiderStockNotify,
      {
        order_id: selectedItem?.id,
        rider_id: selectedItem?.rider_id,
        addtress_id: selectedItem?.address_id
      },
      (onSuccess) => {
        hideAddPro();
        setLoader(false);

      },
      (onFailure) => {
        hideAddPro();
        setLoader(false);

      }
    );
  }

  const OrderHeaders = [
    { name: "Product", key: "product", type: "object_text", path: "", object_value: 'name' },
    { name: "Product id #", key: "product_id", type: "text", path: "/user/" },
    { name: "Order id#", key: "order_id", type: "text", path: "/user/" },
    { name: "Rider id #", key: "rider_id", type: "text", path: "/user/" },
    { name: "Quantity", key: "quantity", type: "text", path: "/user/" },
  ];



  useEffect(() => {
    getAllProducts()
  }, []);

  const [productId, setProductId] = useState(0);
  const [products, setProducts] = useState([]);

  const [loader, setLoader] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  const handleSubmit = (event) => {
    setLoader(true)
    event.preventDefault();
    const body = new FormData(event.target);
    apiPost(
      ENDPOINTS.AddRiderStock,
      body,
      (onSuccess) => {
        event.target.reset();
        setReloadData(!reloadData)
        setLoader(false)

        // hideAddPro();
        // refreshDataPro();

      },
      (onFailure) => {
        setProductId(0)
        event.target.reset();
        setReloadData(!reloadData)
        setLoader(false)


        // hideAddPro();
        // refreshDataPro();
        // setLoader(false);

      }
    );
  };

  // product
  const getAllProducts = () => {
    apiGetAuth(
      ENDPOINTS.AllProductDropdownStock,
      (res) => {
        setProducts(res)
      },
      (error) => {
        console.log(error, "getAllProducts")
      }
    );
  }





  return (
    <>
      <Modal show={showAddPro} onHide={() => hideAddPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Riders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row m-2" onSubmit={handleSubmit}>
            <div className="col-md-12 border border-primary p-4 mb-4">
              <div>
                <h3>Product assign to rider</h3>
                <label className="mb-2 d-block">Product</label>
                <Select
                  name="product_id"
                  formatOptionLabel={(option) => (
                    <div>
                      {option.image ? (
                        <img
                          className="dropdown-image"
                          src={BASE_IMG_URL + option?.image}
                        />
                      ) : (
                        <img className="dropdown-image" src={UserImg} />
                      )}
                      <span>{option.label} quantity : {option.current_qty}</span>
                    </div>
                  )}
                  options={products}
                  onChange={(e) => setProductId(e.value)}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">Quantity</label>
                <input
                  className="form-control"
                  type="text"
                  name="quantity"
                  required
                />

                <input
                  type="hidden"
                  name="order_id"
                  value={selectedItem?.id}
                />
                <input
                  type="hidden"
                  name="rider_id"
                  value={selectedItem?.rider_id}
                />
              </div>
              <button type="submit" className="btn btn-primary mt-3 d-block w-100">Add</button>

            </div>
          </form>
          <PaginatedData
            title="Stock"
            endPoint={ENDPOINTS.AllRiderStock}
            headers={OrderHeaders}
            searchObject={{ order_id: selectedItem?.id, rider_id: selectedItem?.rider_id }}
            reloadData={reloadData}

          />
          <button onClick={sendNotification} type="button" className="btn btn-primary w-100 save-item">Save</button>

        </Modal.Body>
      </Modal>
      <div className="">{loader && <Loader />}</div>

    </>
  );
};

export default AssignStockRider;
