import React from 'react';

const statusColors = {
    pending: '#FFD700',
    preparing: '#6495ED',
    riding: '#7FFF00',
    completed: '#008000',
    delivered: '#31db13',
    cancelled: '#FF0000',
};

const OrderStatus = ({ status }) => {


    const color = statusColors[status];

    return (
        <div className='order-status' style={{ backgroundColor: color }}>
            {status.replace(/_/g, ' ')}
        </div>
    );
};

export default OrderStatus;