import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from "react";

import { Button } from 'react-bootstrap';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { apiGetAuth, apiPost } from '../Util/ApiRequest';
import { ENDPOINTS } from '../Util/EndPoint';
import UsersYearBar from "../components/charts/UsersYearBar";

const Dashboard = () => {

  useEffect(() => {
    getContent()
  }, []);


  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");

  const handleSubmit = () => {
    apiPost(
      ENDPOINTS.SaveAppContent,
      { text: text, },
      (res) => { console.log(res) },
      (error) => { console.log(error) }
    );
  };

  // product
  const getContent = () => {
    apiGetAuth(
      ENDPOINTS.SaveAppContent,
      (res) => {
        setText(res)
      },
      (error) => {
        console.log(error, "getAllProducts")
      }
    );
  }


  return (
    <>
      <Container fluid className="mt-4">
        <Row>
          <Col md={6}>

            <div className="mt-2 card-header-customs ">

              <Card >
                <Card.Body>
                  <Card.Title>App Content</Card.Title>
                  <CKEditor
                    disabled={edit}
                    editor={ClassicEditor}
                    data={text}
                    onReady={editor => {
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />
                  <Button variant="primary" className='mt-2' onClick={() => {
                    setEdit(!edit)
                    if (edit) {
                      handleSubmit()
                    }
                  }
                  } >{edit ? "Edit" : "Save"}</Button>
                </Card.Body>
              </Card>
            </div >
          </Col>
          <Col md={6}>
            <Card>
              <UsersYearBar />
            </Card>
          </Col>

        </Row>

      </Container>

    </>
  );
};

export default Dashboard;
