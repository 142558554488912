import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiGetAuth, apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import Loader from "../global/Loader";

const AssignOrder = ({ showAddPro, hideAddPro, selectedItem }) => {
  useEffect(() => {
    ridersHandle()
  }, []);

  const [loader, setLoader] = useState(false);
  const [riders, setRiders] = useState([]);

  const handleSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    const body = new FormData(event.target);
    apiPost(
      ENDPOINTS.OrderAssignToRider,
      body,
      (onSuccess) => {
        hideAddPro();
        setLoader(false);

      },
      (onFailure) => {
        hideAddPro();
        setLoader(false);

      }
    );
  };
  const ridersHandle = () => {
    apiGetAuth(
      ENDPOINTS.AllRidersDropdown,
      (onSuccess) => {
        console.log(onSuccess, '---onSuccess--onSuccess')
        setRiders(onSuccess)
      },
      (onFailure) => {
        console.log(onFailure, "onFailure")
      }
    );
  };
  return (
    <>
      <Modal show={showAddPro} onHide={() => hideAddPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Riders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label className="mb-3">Choose rider</label>

            <select name="rider_id" className="form-select mb-3" >
              <option >select rider</option>

              {riders?.length > 0 &&
                riders.map((item, index) => (
                  <option selected={selectedItem.rider_id == item.id ? true : false} key={index} value={item.id}>{item.full_name}</option>
                ))}
            </select>
            <input type="hidden" value={selectedItem.id} name="order_id" />
            <input className="btn btn-secondary" type="submit" />
          </form>
        </Modal.Body>
      </Modal>
      <div className="">{loader && <Loader />}</div>

    </>
  );
};

export default AssignOrder;
