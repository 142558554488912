import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSetRecoilState } from 'recoil';

import Loader from '../components/global/Loader';
import SnackBar from '../components/global/SnackBar';
import { apiGetAuth, apiPost } from '../Util/ApiRequest';
import { ProductState } from '../Util/atom';
import { ENDPOINTS } from '../Util/EndPoint';

const ProductCreate = ({ }) => {
  const setProductState = useSetRecoilState(ProductState);

  const [productAttributesAll, setProductAttributesAll] = useState([]);
  const [items, setItems] = useState([]);
  const [hideShowExtra, setHideShowExtra] = useState({
    sideline: false,
    variant: false,
  });
  const [productSideline, setProductSideline] = useState([]);
  const [sidelineId, setSidelineId] = useState([]);
  const [category, setCategory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });

  useEffect(() => {
    getCategories();
    getProductSideline();
    getProductAttribute();
  }, []);

  const getCategories = () => {
    apiGetAuth(
      ENDPOINTS.MealsTypeAllDropDown,
      (res) => {
        setCategory(res);
      },
      (error) => {
        console.log("CreateProducts", error);
      }
    );
  };

  const getProductSideline = () => {
    apiGetAuth(
      ENDPOINTS.ProductSidelines,
      (res) => {
        setProductSideline(res);
      },
      (error) => {
        console.log("ProductSidelineAdd", error);
      }
    );
  };

  const getProductAttribute = () => {
    apiGetAuth(
      ENDPOINTS.AttributesAllDropDown,
      (res) => {
        setItems(res);

        console.log(res, "res");
      },
      (error) => {
        console.log("ProductSidelineAdd", error);
      }
    );
  };

  // after form submit validating the form data using validator
  const submitFormData = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);

    if (sidelineId.length > 0) {
      body.append("sideline_ids", JSON.stringify(sidelineId));
    }

    if (productAttributesAll.length > 0) {
      body.append("product_attributes", JSON.stringify(productAttributesAll));
    }

    apiPost(
      ENDPOINTS.CreateProduct,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        // console.log(res.data);
        setProductState(res.data);

        window.location.href = `/admin/product-detail/${res?.data?.id}`;

        setLoader(false);
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };



  const handleOnChangeSelect = (items) => {
    setSidelineId([]);
    setSidelineId(items);
  };

  const handleOnChangeSelectAttribute = (selectedOption, action) => {
    if (action.action === "remove-value") {
      const updatedArray = productAttributesAll.filter(
        (obj) => obj.value !== action.removedValue.value
      );
      setProductAttributesAll(updatedArray);
    }

    if (action.action === "select-option") {
      let addOption = action.option;
      setProductAttributesAll((prevAttributes) => [
        ...prevAttributes,
        {
          attribute_id: addOption.attribute_id,
          label: addOption.label,
          value: addOption.value,
        },
      ]);
    }
  };



  return (
    <>
      <form className="" onSubmit={submitFormData}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 bg-primary p-4  border shadow rounded ">
              <div className="">
                <label className="mb-2 text-white">Category</label>
                <select name="meals_type_id" className="form-control">
                  {category?.length > 0 &&
                    category.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="">
                <label className="mb-2 text-white">Name:</label>
                <input
                  className="form-control "
                  type="text"
                  name="name"
                  required
                />
              </div>

              <div>
                <label className="mb-2 text-white">Description:</label>
                <textarea
                  className="form-control "
                  name="description"
                  required
                ></textarea>
              </div>

              <div>
                <label className="mb-2 text-white">cook_type:</label>
                <input
                  className="form-control"
                  type="text"
                  name="cook_type"
                  required
                />
              </div>

              <div className="">
                <label className="mb-2 text-white"> product sideline</label>
                <select required name="is_sideline" className="form-control">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="">
                <label className="mb-2 text-white"> frozen</label>
                <select required name="frozen" className="form-control">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

              <div>
                <label className="mb-2 text-white">price:</label>
                <input
                  className="form-control"
                  type="number"
                  name="price"
                  required
                />
              </div>

              <div>
                <label className="mb-2 text-white ">image:</label>
                <input
                  className="form-control mb-4"
                  type="file"
                  name="image"
                  required
                />
              </div>
              {productAttributesAll.length < 1 && (
                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="exampleCheckbox1"
                    value={hideShowExtra.variant}
                    onChange={(e) =>
                      setHideShowExtra({
                        ...hideShowExtra,
                        variant: !hideShowExtra.variant,
                      })
                    }
                  />
                  <label
                    className="form-check-label mb-2 text-white ms-2"
                    htmlFor="exampleCheckbox1"
                  >
                    add variant
                  </label>
                </div>
              )}

              {sidelineId.length < 1 && (
                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="exampleCheckbox"
                    value={hideShowExtra.sideline}
                    onChange={(e) =>
                      setHideShowExtra({
                        ...hideShowExtra,
                        sideline: !hideShowExtra.sideline,
                      })
                    }
                  />
                  <label
                    className="form-check-label mb-2 text-white ms-2"
                    htmlFor="exampleCheckbox"
                  >
                    add sideline {hideShowExtra.sideline.toString()}
                  </label>
                </div>
              )}

              <button className="btn btn-secondary mt-2" type="submit">
                submit
              </button>
            </div>

            <div className="col-md-6 ">
              {hideShowExtra.sideline && (
                <div className="bg-primary border shadow rounded p-4 mb-5">
                  <label className="mb-2 d-block text-white">
                    Product Sideline
                  </label>
                  <Select
                    onChange={handleOnChangeSelect}
                    isMulti
                    options={productSideline}
                  />
                </div>
              )}

              {hideShowExtra.variant && (
                <div className="bg-primary border shadow rounded p-4">
                  {items?.length > 0 &&
                    items.map((item, index) => (
                      <div key={index} className="mb-2">
                        <label className=" d-block text-white">
                          Select {item?.name}
                        </label>
                        <span className="font-10 mb-2 text-danger">
                          select {item?.name} if product has
                        </span>
                        <Select
                          onChange={handleOnChangeSelectAttribute}
                          isMulti
                          id={item.id}
                          name={item?.name}
                          options={item?.attribute_values_dropdown}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <SnackBar
        closeSnackPro={() => setSnackInfo({ snackStatus: false })}
        snackInfoPro={snackInfo}
      />
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default ProductCreate;
