import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiPost } from "../../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";

const UpdateMealType = ({
  showUpdateModalPro,
  hideUpdateModalPro,
  selectedItemPro,
  refreshDataPro,
}) => {
  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });

  const nameEl = React.useRef(null);
  const description = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setLoader(true);
    apiPost(
      ENDPOINTS.UpdateMealsType,
      body,
      (res) => {
        hideUpdateModalPro();
        refreshDataPro();
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
      },
      (error) => {
        hideUpdateModalPro();
        refreshDataPro();
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  return (
    <>
      <Modal show={showUpdateModalPro} onHide={() => hideUpdateModalPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Update Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label className="mb-2">Name:</label>
            <input
              className="form-control mb-4"
              name="name"
              type="text"
              required
              defaultValue={selectedItemPro?.name}
              ref={nameEl}
              onChange={(e) => (nameEl.current.value = e.target.value)}
            />
            <label className="mb-2">Description</label>
            <textarea
              className="form-control mb-4"
              required
              defaultValue={selectedItemPro?.description}
              name="description"
              ref={description}
              onChange={(e) => (description.current.value = e.target.value)}
            ></textarea>
            <img
              src={
                selectedItemPro.image
                  ? BASE_IMG_URL + selectedItemPro.image
                  : CatImg
              }
              className="no-image-custom"
            />
            <label className="mb-2 mt-3">image</label>
            <input className="form-control mb-4" name="image" type="file" />
            <input
              type="hidden"
              name="meal_type_id"
              value={selectedItemPro.id}
            />
            <input className="btn btn-secondary" type="submit" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateMealType;
