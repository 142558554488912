import React from "react";

const HtmlImage = ({ urlPro }) => {
  return (
    <div>
      <img src={urlPro} alt="Wish Images" />
    </div>
  );
};

export default HtmlImage;
