import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import Loader from "../global/Loader";
import SnackBar from "../global/SnackBar";
const CreateRyder = ({ showAddPro, hideAddPro, refreshDataPro }) => {
  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });
  const [formValue, setFormValue] = useState({
    name: "",
    attributes_value: "",
  });

  const handleSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    const body = new FormData(event.target);

    apiPost(
      ENDPOINTS.CreateRyder,
      body,
      (onSuccess) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Rider created successful",
        });
        hideAddPro();
        refreshDataPro();
        setLoader(false);
      },
      (onFailure) => {
        console.log(onFailure?.data?.message[0], "onFailure");
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: `${onFailure?.data?.message[0]}`,
        });
        // hideAddPro();
        // refreshDataPro();  
      }
    );
  };
  return (
    <>
      <SnackBar
        closeSnackPro={() => setSnackInfo({ snackStatus: false })}
        snackInfoPro={snackInfo}
      />
      <Modal show={showAddPro} onHide={() => hideAddPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Create rider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="mb-2">Email</label>
              <input
                className="form-control mb-4"
                name="email"
                type="text"
                required
              />
            </div>

            <div className="mb-4">
              <label className="mb-2">Full name</label>
              <input
                className="form-control mb-4"
                name="full_name"
                type="text"
                required
              />
            </div>

            <div className="mb-4">
              <label className="mb-2">Mobile</label>
              <input
                className="form-control mb-4"
                name="mobile"
                type="number"
                required
              />
            </div>

            <div className="mb-4">
              <label className="mb-2">Password</label>
              <input
                className="form-control mb-4"
                name="password"
                type="password"
                required
              />
            </div>

            <div className="mb-4">
              <label className="mb-2">Confirm password</label>
              <input
                className="form-control mb-4"
                name="confirm_password"
                type="password"
                required
              />

              <input
                className="form-control mb-4"
                name="device_token"
                type="hidden"
                value="sdadsa"
              />

              <input
                className="form-control mb-4"
                name="role"
                type="hidden"
                value="rider"
              />
              <input
                className="form-control mb-4"
                name="longitude"
                type="hidden"
                value="756756765"
              />

              <input
                className="form-control mb-4"
                name="latitude"
                type="hidden"
                value="756756765"
              />
            </div>

            <div>
              <label className="mb-2">Image</label>
              <input
                className="form-control mb-4"
                type="file"
                name="image"
                required
              />
            </div>
            <input className="btn btn-secondary" type="submit" />
          </form>
        </Modal.Body>
      </Modal>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default CreateRyder;
