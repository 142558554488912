export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {
  //User
  UserLogin: `${BASE_URL}api/auth/login`,
  CreateRyder: `${BASE_URL}api/auth/sign-up`,

  // meals type

  MealsTypeAll: `${BASE_URL}api/admin/meals-type/all`,
  UpdateMealsType: `${BASE_URL}api/admin/meals-type/update`,
  CreateMealsType: `${BASE_URL}api/admin/meals-type/create`,

  MealsTypeAllDropDown: `${BASE_URL}api/meals-type/all`,

  // meals type
  AttributesAll: `${BASE_URL}api/admin/attributes/all`,
  AttributesAllDropDown: `${BASE_URL}api/admin/attributes/all-dropdown`,
  AttributesAllDropDownDefault: `${BASE_URL}api/admin/attributes/all-dropdown-default`,
  UpdateAttributes: `${BASE_URL}api/admin/attributes/update`,
  CreateAttributes: `${BASE_URL}api/admin/attributes/create`,
  CreateProductsAttributes: `${BASE_URL}api/admin/product/product-attribute-create`,

  //All Users
  AllUsersWithPagination: `${BASE_URL}api/admin/user/get-all-user`,
  AllRidersWithPagination: `${BASE_URL}api/admin/user/get-all-riders`,
  UserActiveDeActiveByAdmin: `${BASE_URL}api/admin/user/active-deactive-user`,
  UserDetails: `${BASE_URL}api/admin/user/user-detail`,
  AllUserAddresses: `${BASE_URL}api/admin/user/user-addresses`,
  AllUserDropDown: `${BASE_URL}api/admin/user/dropdown-all-user`,

  // product
  CreateProduct: `${BASE_URL}api/admin/products/create`,
  ProductSidelines: `${BASE_URL}api/admin/products/all-sideline-dropdown`,
  CreateProductSideline: `${BASE_URL}api/admin/products/create-product-sideline`,
  ProductSidelinesSingle: `${BASE_URL}api/admin/products/get-product-sideline/`,
  ProductVariantsSingle: `${BASE_URL}api/admin/products/get-product-variants/`,
  ProductDetail: `${BASE_URL}api/admin/products/detail-product/`,
  ProductAll: `${BASE_URL}api/admin/product/all`,
  UpdateProduct: `${BASE_URL}api/admin/products/update`,
  StatusProduct: `${BASE_URL}api/admin/products/active-deactive`,
  AllProductDropdownStock: `${BASE_URL}api/admin/products/all-dropdown-stock`,

  /// stock
  StockPaginated: `${BASE_URL}api/admin/stock/all`,
  AddStock: `${BASE_URL}api/admin/stock/add`,

  //voucher
  VoucherPaginated: `${BASE_URL}api/admin/voucher/all`,
  VoucherCreate: `${BASE_URL}api/admin/voucher/create`,

  ProductSidelinePriceUpdate: `${BASE_URL}api/admin/product-sideline-price-update`,
  ProductSidelineDetailUpdate: `${BASE_URL}api/admin/product/product-detail-sideline-update`,
  ProductSidelineDelete: `${BASE_URL}api/admin/product/delete-product-sideline`,
  ProductAttributeDelete: `${BASE_URL}api/admin/product/delete-product-attribute`,
  ProductVariantPriceUpdate: `${BASE_URL}api/admin/product-variant-price-update`,
  ProductVariantUpdate: `${BASE_URL}api/admin/product/update-product-attribute`,

  // header
  AppHeader: `${BASE_URL}admin/user/dashboard-header`,
  ChartCurrentYearUsers: `${BASE_URL}api/admin/chart/current-year-users`,
  ChartCurrentYearWishes: `${BASE_URL}api/admin/chart/current-year-wishes`,

  //Tags
  PaginatedWishTags: `${BASE_URL}api/admin/tag-wish/get-all-tags`,
  CreateTagByAdmin: `${BASE_URL}api/admin/tag-wish/create`,
  UpdateTagByAdmin: `${BASE_URL}api/admin/tag-wish/update-tag`,

  //Wishes

  //inter active wishes
  SingleUserInterActiveWises: `${BASE_URL}api/admin/user/single-user-wishes-inter-active-wishes`,
  AllReportedWishes: `${BASE_URL}api/admin/wishes/reported-wishes`,

  //orders
  OrdersPaginated: `${BASE_URL}api/admin/orders/all`,
  OrderAssignToRider: `${BASE_URL}api/admin/orders/rider-assign`,


  AllRidersDropdown: `${BASE_URL}api/admin/user/all-riders-dropdown`,

  // user details
  AddRiderStock: `${BASE_URL}api/admin/rider-stock/add`,
  AllRiderStock: `${BASE_URL}api/admin/rider-stock/all`,



  RiderStockNotify: `${BASE_URL}api/admin/rider-stock/notify`,
  SaveAppContent: `${BASE_URL}api/admin/app-content`,
};
