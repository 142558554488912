import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import UserImg from '../assets/images/user.jpg';
import Loader from '../components/global/Loader';
import SnackBar from '../components/global/SnackBar';
import { apiGetAuth, apiPost } from '../Util/ApiRequest';
import { ENDPOINTS } from '../Util/EndPoint';

const VouchersCreate = ({}) => {
  const [voucherType, setVoucherType] = useState("all");
  const [users, setUsers] = useState([]);

  const [usersSelected, setUsersSelected] = useState([]);

  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });

  useEffect(() => {
    getUsers();
  }, []);

  const voucherForHandleChange = (value) => {
    setVoucherType(value);
  };
  const getUsers = () => {
    apiGetAuth(
      ENDPOINTS.AllUserDropDown,
      (res) => {
        setUsers(res);
      },
      (error) => {
        console.log("CreateProducts", error);
      }
    );
  };

  const handleUsersSelect = (item) => {
    setUsersSelected([]);
    setUsersSelected(item);
  };
  const handleSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    const body = new FormData(event.target);

    if (usersSelected.length > 0) {
      body.append("user_ids", JSON.stringify(usersSelected));
    }

    apiPost(
      ENDPOINTS.VoucherCreate,
      body,
      (onSuccess) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        window.location.href = `/admin/vouchers`;
      },
      (onFailure) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  return (
    <>
      <form className="" onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 bg-primary p-4  border shadow rounded ">
              <div className="">
                <label className="mb-2 text-white">code:</label>
                <input
                  className="form-control "
                  type="text"
                  name="code"
                  required
                  min={8}
                />
              </div>
              <div>
                <label className="mb-2 text-white">max_used:</label>
                <input
                  className="form-control"
                  type="number"
                  name="max_used"
                  required
                  min={0}
                  defaultValue={0}
                />
              </div>

              <div>
                <label className="mb-2 text-white">start_at:</label>
                <input
                  className="form-control"
                  type="date"
                  name="start_at"
                  required
                />
              </div>
              <div>
                <label className="mb-2 text-white">expire_at:</label>
                <input
                  className="form-control"
                  type="date"
                  name="expire_at"
                  required
                />
              </div>

              <div className="">
                <label className="mb-2 text-white">dis_type</label>
                <select required name="dis_type" className="form-control">
                  <option value="flat">Flat</option>
                  <option value="percent">Percent</option>
                </select>
              </div>

              <div>
                <label className="mb-2 text-white">dis_value:</label>
                <input
                  className="form-control"
                  type="number"
                  name="dis_value"
                  required
                  min={0}
                  defaultValue={0}
                />
              </div>

              <div className="">
                <label className="mb-2 text-white"> voucher_for</label>
                <select
                  required
                  name="voucher_for"
                  onChange={(e) => voucherForHandleChange(e.target.value)}
                  className="form-control"
                >
                  <option value="all">All</option>
                  <option value="user">User</option>
                </select>
              </div>

              <button className="btn btn-secondary mt-2" type="submit">
                submit
              </button>
            </div>

            {voucherType == "user" && (
              <div className="col-md-6 bg-primary p-4  border shadow rounded ">
                <div className="mb-2">
                  <label className=" d-block text-white">Select Users</label>
                  <Select
                    formatOptionLabel={(option) => (
                      <div>
                        {option.image ? (
                          <img
                            className="dropdown-image"
                            // src={BASE_IMG_URL + option?.image}
                          />
                        ) : (
                          <img className="dropdown-image" src={UserImg} />
                        )}
                        <span>{option.label}</span>
                      </div>
                    )}
                    onChange={handleUsersSelect}
                    isMulti
                    options={users}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <SnackBar
        closeSnackPro={() => setSnackInfo({ snackStatus: false })}
        snackInfoPro={snackInfo}
      />
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default VouchersCreate;
