import React from 'react'
import { BASE_IMG_URL } from '../../Util/EndPoint'
import UserImg from "../../assets/images/user.jpg";

export const UserAvatar = ({ userObjPro }) => {
    return (
        <div className="d-flex gap-2">
            <img
                src={
                    userObjPro?.image
                        ? BASE_IMG_URL + userObjPro?.image
                        : UserImg
                }
                className="user-img-table"
            />
            <div>
                <h5 className="mb-0">{userObjPro?.full_name}</h5>
                <p className="mb-0">{userObjPro?.mobile}</p>
            </div>

        </div>
    )
}
