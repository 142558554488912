import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiGetAuth, apiPost } from "../../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";




const UpdateProducts = ({
  showUpdateModalPro,
  hideUpdateModalPro,
  selectedItemPro,
  refreshDataPro,
}) => {

  useEffect(() => {
    getCategories();
  }, []);



  const [category, setCategory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });




  const getCategories = () => {
    apiGetAuth(
      ENDPOINTS.MealsTypeAllDropDown,
      (res) => {
        setCategory(res);
      },
      (error) => {
        console.log("CreateProducts", error);
      }
    );
  };

  const submitFormData = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);

    setLoader(true);
    event.preventDefault();

    setLoader(true);
    apiPost(
      ENDPOINTS.UpdateProduct,
      body,
      (res) => {
        hideUpdateModalPro();
        refreshDataPro();
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
      },
      (error) => {
        hideUpdateModalPro();
        refreshDataPro();
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  return (
    <>
      <Modal show={showUpdateModalPro} onHide={() => hideUpdateModalPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Update Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="" onSubmit={submitFormData}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 bg-primary p-4  border shadow rounded ">
                  <div className="">
                    <label className="mb-2 text-white">Category </label>
                    <select name="meals_type_id" className="form-control" >
                      {category?.length > 0 &&
                        category.map((item, index) => (
                          <option key={index} value={item.id} selected={item.id === selectedItemPro.meals_type_id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="">
                    <label className="mb-2 text-white">Name:</label>
                    <input
                      className="form-control "
                      type="text"
                      name="name"
                      required
                      defaultValue={selectedItemPro.name}
                    />
                  </div>

                  <div>
                    <label className="mb-2 text-white">Description:</label>
                    <textarea
                      className="form-control "
                      name="description"
                      required
                      defaultValue={selectedItemPro.description}
                    ></textarea>
                  </div>

                  <div>
                    <label className="mb-2 text-white">cook_type:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="cook_type"
                      required
                      defaultValue={selectedItemPro.cook_type}
                    />

                    <input
                      type="hidden"
                      name="product_id"
                      value={selectedItemPro.id}
                    />
                  </div>

                  <div className="">
                    <label className="mb-2 text-white"> product sideline</label>
                    <select required name="is_sideline" className="form-control">
                      <option value="1" selected={selectedItemPro.is_sideline == 1}>Yes</option>
                      <option value="0" selected={selectedItemPro.is_sideline == 0}>No</option>
                    </select>
                  </div>
                  <div className="">
                    <label className="mb-2 text-white"> frozen</label>
                    <select required name="frozen" className="form-control">
                      <option value="1" selected={selectedItemPro.frozen == 1}>Yes</option>
                      <option value="0" selected={selectedItemPro.frozen == 0}>No</option>
                    </select>
                  </div>

                  <div>
                    <label className="mb-2 text-white">price:</label>
                    <input
                      className="form-control"
                      type="number"
                      name="price"
                      required
                      defaultValue={selectedItemPro.price}

                    />
                  </div>
                  <img
                    src={
                      selectedItemPro.image
                        ? BASE_IMG_URL + selectedItemPro.image
                        : CatImg
                    }
                    className="no-image-custom mt-3"
                  />
                  <div>
                    <label className="mb-2 text-white ">image:</label>
                    <input
                      className="form-control mb-4"
                      type="file"
                      name="image"
                    />
                  </div>
                  <button className="btn btn-secondary mt-2" type="submit">
                    submit
                  </button>
                </div>


              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateProducts;
