import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaArrowUp, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { BASE_IMG_URL, ENDPOINTS } from '../../Util/EndPoint';
import CatImg from "../../assets/images/user.jpg";
import "../../assets/pages-css/user-detail.css";
import MyAddress from './MyAddress';
import PaginatedData from './PaginatedData';
import UserOverView from "./UserOverView";

const UserProfileCard = ({ userObjPro }) => {
  const OrderHeaders = [
    { name: "User", key: "user", type: "user_card", path: "/user/" },
    { name: "Rider", key: "rider", type: "rider_card", path: "/user/" },
    { name: "Payment", key: "payment_method", type: "text", path: "" },
    { name: "Status", key: "status", type: "order_status", path: "" },
    { name: "Gross amount", key: "gross_amount", type: "text", path: "" },
    { name: "Discount amount", key: "dis_amount", type: "text", path: "" },
    { name: "Net amount", key: "net_amount", type: "text", path: "" },
    { name: "Date", key: "created_at", type: "date", path: "" },

  ];

  return (

    <div className=' user-profile-main'>
      <div className='user-profile'>
        <div className='user-image-box'>
          <img src={userObjPro?.image ? BASE_IMG_URL + userObjPro?.image : CatImg} alt="profile image" className='user-image' />
        </div>

        <div className='user-info-box'>
          <div className='user-info-box-personal'>
            <h3>{userObjPro?.full_name} <span className='font-icon-14 text-success'><GoVerified /></span></h3>
            <div className='info-icon'>
              <p><span className='font-icon-12'><FaEnvelope /></span>  {userObjPro?.email}</p>
              <p> <span className='font-icon-12'><FaPhoneAlt /></span> {userObjPro?.mobile}</p>
            </div>
          </div>
          <div className='user-info-app'>
            <div className='info-icon-app'>
              <h5><span className='text-success font-icon-12'><FaArrowUp /></span> {userObjPro?.orders_count ? userObjPro.orders_count : 0}</h5>
              <p>total order</p>
            </div>
          </div>
        </div>

      </div>

      <div className='mt-4  user-profile-nav-tabs'>
        <Tabs
          defaultActiveKey="overview"
          className="mb-3"
        >
          <Tab eventKey="overview" title="Overview">
            <UserOverView userObjPro={userObjPro} />
          </Tab>
          <Tab eventKey="my-wishes" title="My Address">
            <MyAddress />
          </Tab>
          <Tab eventKey="orders-user" title="Order">

            {userObjPro?.id > 0 && (
              <PaginatedData
                title="Orders"
                endPoint={ENDPOINTS.OrdersPaginated}
                headers={OrderHeaders}
                searchObject={{ user_id: userObjPro?.id }}
              />
            )}

          </Tab>
        </Tabs>
      </div>
    </div>

  )
}

export default UserProfileCard