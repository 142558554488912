import React, { useState } from 'react';
import { FaBars, FaBezierCurve, FaHome, FaMapSigns, FaMoneyCheckAlt, FaShoppingCart, FaSlack, FaTh, FaUserAltSlash, FaUserFriends, FaWindowClose } from 'react-icons/fa';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

const SidebarComponent = () => {
  const [urlParam, setUrlParam] = useState(
    window.location.pathname.split("/").pop()
  );

  const [burgerShow, setBurgerShow] = useState(false);

  const logOut = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  return (
    <>

      <Sidebar
        className="sidebar-dashboard"
        backgroundColor="#0a0a0a"
        defaultCollapsed={burgerShow}
      >
        <div className="sidebar-dashboard-logo">
          <button onClick={() => setBurgerShow(!burgerShow)}> {burgerShow ? <FaWindowClose /> : <FaBars />}</button>
        </div>

        <Menu iconShape="square">
          <MenuItem
            active={urlParam === "dashboard" ? true : false}
            onClick={() => setUrlParam("dashboard")}
            icon={<FaHome />}
            className="sidebar-menu-design  sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/dashboard">
              Dashboard
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "attributes" ? true : false}
            onClick={() => setUrlParam("attributes")}
            icon={<FaSlack />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/attributes">
              Attributes
            </Link>
          </MenuItem>
          <MenuItem
            active={urlParam === "meals-type" ? true : false}
            onClick={() => setUrlParam("meals-type")}
            icon={<FaSlack />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/meals-type">
              Meals Type
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "users" ? true : false}
            onClick={() => setUrlParam("users")}
            icon={<FaUserFriends />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/users" >
              Users
            </Link>
          </MenuItem>
          <MenuItem
            active={urlParam === "riders" ? true : false}
            onClick={() => setUrlParam("riders")}
            icon={<FaBezierCurve />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/riders"}>
              Riders
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "products" ? true : false}
            onClick={() => setUrlParam("products")}
            icon={<FaMapSigns />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/products" >
              Products
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "vouchers" ? true : false}
            onClick={() => setUrlParam("vouchers")}
            icon={<FaMoneyCheckAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/vouchers" >
              Vouchers
            </Link>
          </MenuItem>
          <MenuItem
            active={urlParam === "orders" ? true : false}
            onClick={() => setUrlParam("orders")}
            icon={<FaShoppingCart />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/orders">
              Orders
            </Link>
          </MenuItem>
          <MenuItem
            active={urlParam === "stocks" ? true : false}
            onClick={() => setUrlParam("stocks")}
            icon={<FaTh />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to="/admin/stocks">
              Stocks
            </Link>
          </MenuItem>

          <MenuItem onClick={() => logOut()} icon={<FaUserAltSlash />} className="sidebar-menu-design">            Logout          </MenuItem>
        </Menu>
      </Sidebar>

    </>
  );
};

export default SidebarComponent;
