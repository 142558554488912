import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';
import './custom.scss';

import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AdminLayout from './components/layout/AdminLayout';
import Attributes from './pages/Attributes';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/ErrorPage';
import Login from './pages/Login';
import MealsType from './pages/MealsType';
import Orders from './pages/Orders';
import ProductCreate from './pages/ProductCreate';
import ProductDetail from './pages/ProductDetail';
import Products from './pages/Products';
import RiderStock from './pages/RiderStock';
import Riders from './pages/Riders';
import Stocks from './pages/Stocks';
import Users from './pages/Users';
import UsersDetail from './pages/UsersDetail';
import Vouchers from './pages/Vouchers';
import VouchersCreate from './pages/VouchersCreate';

function App () {
  return (
    <>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path={``} element={<Login />} />
            <Route path={`/`} element={<AdminLayout />}>
              <Route exact path="admin/dashboard" element={<Dashboard />} />
              <Route exact path="admin/meals-type" element={<MealsType />} />
              <Route exact path="admin/attributes" element={<Attributes />} />
              <Route exact path="admin/orders" element={<Orders />} />
              <Route exact path="admin/stocks" element={<Stocks />} />
              <Route exact path="admin/rider-stock" element={<RiderStock />} />
              <Route exact path="admin/user-detail/:id" element={<UsersDetail />} />
              <Route exact path="admin/product-detail/:id" element={<ProductDetail />} />
              <Route exact path="admin/product-create" element={<ProductCreate />} />
              <Route exact path="admin/users" element={<Users />} />
              <Route exact path="admin/riders" element={<Riders />} />
              <Route exact path="admin/vouchers" element={<Vouchers />} />
              <Route exact path="admin/vouchers-create" element={<VouchersCreate />} />
              <Route exact path="admin/products" element={<Products />} />
              <Route exact path="admin/voucher-detail/:id" element={<UsersDetail />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
