import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { apiPost } from "../../Util/ApiRequest";
import CustomTooltip from "./CustomTooltip";
import Loader from "./Loader";
import OrderStatus from "./OrderStatus";
import { RiderAvatar } from "./RiderAvatar";
import { UserAvatar } from "./UserAvatar";
// import SingleImage from "./SingleImage";
// import UserInfoCard from "./UserInfoCard"

const PaginatedData = ({ title, endPoint, headers, reloadData, searchObject, selectItem }) => {
    const [items, setItems] = useState([]);
    const [currentUrl, setCurrentUrl] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        console.log(searchObject, '----searchObject---')
        const url = "";
        setCurrentUrl(url);
        getPaginatedUsers(url);
    }, [reloadData]);

    const handlePageClick = (item) => {
        const url = `?page=${item.selected + 1}&limit=10`;
        getPaginatedUsers(url);
    };

    const getPaginatedUsers = (url) => {
        setLoader(true);
        apiPost(
            endPoint + url,
            searchObject,
            (res) => {

                setItems(res?.data?.results);
                setTotalPage(res?.data?.meta?.totalPages);
                setLoader(false);
            },
            (error) => {
                console.log(error);
                setLoader(false);
            }
        );
    };


    const renderHeaderComponent = (item, header) => {
        switch (header.type) {
            case 'object_text':
                return <p className="my-auto">{item[header?.key][header?.object_value] ? item[header?.key][header?.object_value] : '-'}</p>;

            case 'text':
                return <p className="my-auto">{item[header?.key] ? item[header?.key] : '-'}</p>;


            case 'null_status':
                return <p className="my-auto">{item[header?.key] ? "Yes" : "No"}</p>;


            case 'boolean_status':
                return <p className="my-auto">{item[header?.key] == 0 ? "No" : "Yes"}</p>;

            case 'public_event':
                return <p className="my-auto">{item[header?.key] == 0 ? "Private" : "Public"}</p>;


            case 'select_item':
                return <button className="btn btn-primary btn-sm" onClick={() => selectItem(item)} >Show</button>;


            case 'tool_tip':
                return <CustomTooltip descriptionPro={item[header?.key]} />

            case 'user_card':
                return <UserAvatar userObjPro={item[header?.key]} />

            case 'rider_card':
                return <RiderAvatar userObjPro={item[header?.key]} />

            case 'order_status':
                return <p className="mb-0"><OrderStatus status={item[header?.key]} /></p>


            // case 'single_image':
            //     return <SingleImage image={item[header?.key]} />

            case 'date':
                return <p className="mb-0">  {moment(item[header?.key]).format("YYYY-MM-DD HH:mm:ss")}</p>

            case 'href':
                return item[header?.key] ?
                    <a href={`${process.env.REACT_APP_API_BASE_PATH}${header?.path}${item?.id}`} className="btn btn-sm btn-secondary font-12">Detail</a> :
                    <span className="text-white p-2 bg-danger font-12 rounded">Not created</span>;

            default:
                return <p></p>;
        }
    };

    return (
        <>
            <div>

                <div className="table-main-header p-2">
                    <h5 className="text-white my-auto p-2">{title}</h5>
                </div>
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {items?.length > 0 &&
                                items.map((item, index) => (
                                    <tr key={index}>
                                        {headers.map((header, index) => (
                                            <td key={index}>
                                                {renderHeaderComponent(item, header)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    {items?.length == 0 && (
                        <h6 className="text-primary text-center">Record not found</h6>
                    )}

                </div>

                <ReactPaginate
                    className="pagination-custom"
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={totalPage}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </div >
            <div className="mt-5 pb-5">{loader && <Loader />}</div>
        </>
    );
};

PaginatedData.defaultProps = {
    title: "Default Title",
    endPoint: "",
    headers: [],
    reloadData: false,
    searchObject: {},
    selectItem: () => { },
};

export default PaginatedData;
