import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { FaEyeDropper } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

import UserImg from '../assets/images/user.jpg';
import CustomTooltip from '../components/global/CustomTooltip';
import Loader from '../components/global/Loader';
import SnackBar from '../components/global/SnackBar';
import { apiGetAuth, apiPost } from '../Util/ApiRequest';
import { ENDPOINTS,BASE_IMG_URL } from '../Util/EndPoint';

const Products = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [items, setItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [tagsSearch, setTagsSearch] = useState("");

  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getPaginatedProducts(url);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPaginatedProducts(currentUrl);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [tagsSearch]);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    setCurrentUrl(url);
    getPaginatedProducts(url);
  };

  const searchUsersByText = (name) => {
    setTagsSearch(name);
    if (currentUrl) {
      setCurrentUrl(currentUrl + "&search_text=" + name);
      getPaginatedProducts(currentUrl + "&search_text=" + name);
    }
    setCurrentUrl("?search_text=" + name);
    getPaginatedProducts("?search_text=" + name);
  };

  const createProduct = () => {
    window.location.href = `/admin/product-create`;
  };
  const SelectItem = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
  };

  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });

  const getPaginatedProducts = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.ProductAll + url,
      (res) => {
        setLoader(false);
        setItem(res?.results);
        setTotalPage(res?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  const ActiveDeActive = (item) => {
    setLoader(true);
    apiPost(
      ENDPOINTS.StatusProduct,
      {
        product_id: item.id,
      },
      (success) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        getPaginatedProducts(currentUrl);
        setLoader(false);
      },
      (error) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="user-search mb-3">
          <label className="my-auto me-2">Product Search</label>
          <Form.Control
            type="search"
            placeholder="Search User"
            className="me-2 users-search"
            aria-label="Search"
            value={tagsSearch}
            onChange={(e) => searchUsersByText(e.target.value)}
          />
        </div>

        <div className="table-main-header p-2">
          <h5 className="text-white my-auto">Product</h5>
          <Button variant="secondary" onClick={createProduct}>
            Create product
          </Button>
        </div>
        <div className="table-responsive table-customs">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Frozen</th>
                <th>Sideline</th>
                <th>price</th>
                <th>description</th>
                <th>Status</th>
                <th>Current qty</th>
                <th>Created</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={item?.image ? BASE_IMG_URL + item?.image : UserImg}
                        className="user-img-table"
                      />
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.frozen == 1 ? "Yes" : "No"}</td>
                    <td>{item?.is_sideline == 1 ? "Yes" : "No"}</td>
                    <td>{item?.price}</td>

                    <td>
                      <CustomTooltip descriptionPro={item?.description} />
                    </td>
                    <td>
                      <BootstrapSwitchButton
                        size="sm"
                        checked={item?.is_active == 1 ? true : false}
                        onlabel="Active"
                        offlabel="De-active"
                        onChange={() => ActiveDeActive(item)}
                      />
                    </td>
                    <td>{item?.current_qty}</td>
                    <td>{moment(item?.created_at).format("LL")}</td>
                    <td className="position-relative">
                      <button
                        className="no-btn-structure"
                        onClick={() => {
                          window.location.href = `/admin/product-detail/${item.id}`;
                        }}
                      >
                        <span className="text-info">
                          <FaEyeDropper />
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />

        <SnackBar
          closeSnackPro={() => setSnackInfo({ snackStatus: false })}
          snackInfoPro={snackInfo}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default Products;
