import moment from "moment";
import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { apiPost } from "../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../Util/EndPoint";
import UserImg from "../assets/images/user.jpg";
import AssignOrder from "../components/forms/AssignOrder";
import AssignStockRider from "../components/forms/AssignStockRider";
import Loader from "../components/global/Loader";
import OrderStatus from "../components/global/OrderStatus";

const Orders = () => {

  const [assignOrderModal, setAssignOrderModal] = useState(false);
  const [assignStockRider, setAssignStockRider] = useState(false);



  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedItem, setSelectedItem] = useState({});

  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllOrdersPagination(url, "all");
  }, []);

  const handleAssignModal = (item) => {
    setSelectedItem(item);
    setAssignOrderModal(true);
  };


  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllOrdersPagination(url, selectedStatus);
  };

  const getAllOrdersPagination = (url, statusCheck) => {
    console.log(statusCheck, "statusCheck")
    let body = {
      // user_id: userId,
    };

    if (statusCheck) {
      body.status = statusCheck
    }
    setLoader(true);
    apiPost(
      ENDPOINTS.OrdersPaginated + url,
      body,
      (res) => {
        console.log(res, "user Wishes");
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-3">
            <label className="mb-2">Select Status {selectedStatus}</label>
            <Form.Select onChange={(e) => {
              setSelectedStatus(e.target.value)
              getAllOrdersPagination(currentUrl, e.target.value)
            }} aria-label="Default select example">
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="rider_assign">Rider Assign</option>
              <option value="in_route">In route</option>
              <option value="arrived">Arrived</option>
              <option value="delivered">Delivered</option>
              <option value="cancel">Cancel</option>
            </Form.Select>
          </div>
        </div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto">Orders</h5>
        </div>
        <div className="table-responsive table-customs">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>User</th>
                <th>Rider</th>
                <th>Payment method</th>
                <th>Status</th>
                <th>Gross amount</th>
                <th>Discount amount</th>
                <th>Net amount</th>
                <th>Voucher code</th>
                <th>Created</th>
                <th>Order assign</th>
                <th>Rider Stock assign</th>

              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>

                    <td className="">

                      <div className="d-flex gap-2  w-max-content">
                        <img
                          src={
                            item?.user?.image
                              ? BASE_IMG_URL + item?.user?.image
                              : UserImg
                          }
                          className="user-img-table"
                        />
                        <div>
                          <h5 className="mb-0">{item?.user?.full_name}</h5>
                          <p className="mb-0">{item?.user?.mobile}</p>
                        </div>

                      </div>



                    </td>

                    <td>
                      {item?.rider && (
                        <div className="d-flex gap-2 w-max-content">
                          <img
                            src={
                              item?.rider?.image
                                ? BASE_IMG_URL + item?.rider?.image
                                : UserImg
                            }
                            className="user-img-table"
                          />
                          <div>
                            <h5 className="mb-0">{item?.rider?.full_name}</h5>
                            <p className="mb-0">{item?.rider?.mobile}</p>
                          </div>

                        </div>
                      )}
                      {!item?.rider && (
                        <p className="mb-0">
                          not
                          assign</p>
                      )}

                    </td>

                    <td className="position-relative">
                      <p className="mb-0 w-max-content">{item?.payment_method}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 w-max-content"><OrderStatus status={item?.status} /></p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 w-max-content">{item?.gross_amount}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0 w-max-content">{item?.dis_amount}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0 w-max-content">{item?.net_amount}</p>
                    </td>


                    <td className="position-relative">
                      <p className="mb-0 w-max-content">{item?.voucher?.code}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0 w-max-content">
                        {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                      </p>
                    </td>
                    <td>
                      <button className="btn btn-primary" onClick={() => handleAssignModal(item)}>Assign</button>
                    </td>
                    <td>
                      <button className="btn btn-primary" onClick={() => {
                        setSelectedItem(item);
                        setAssignStockRider(true)
                      }}>Assign</button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {items?.length == 0 && (
            <h6 className="text-primary text-center">Record not found</h6>
          )}
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div >
      <div className="">{loader && <Loader />}</div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {selectedItem?.users_voucher?.length > 0 &&
              selectedItem?.users_voucher.map((item, index) => (
                <ListGroupItem key={index}>
                  <div className="d-flex gap-2">
                    <img
                      src={
                        item?.user?.image
                          ? BASE_IMG_URL + item?.user?.image
                          : UserImg
                      }
                      className="user-img-table"
                    />
                    <h5 className="my-auto">{item?.user?.email}</h5>
                  </div>
                </ListGroupItem>
              ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
      <AssignOrder refreshDataPro={() => getAllOrdersPagination(currentUrl, selectedStatus)} selectedItem={selectedItem} showAddPro={assignOrderModal} hideAddPro={() => setAssignOrderModal(false)} />
      <AssignStockRider selectedItem={selectedItem} showAddPro={assignStockRider} hideAddPro={() => setAssignStockRider(false)} />
    </>
  );
};

export default Orders;
