import React, { useEffect, useState } from 'react';
import { apiPost } from '../Util/ApiRequest';
import { ENDPOINTS } from '../Util/EndPoint';
import HeaderCard from '../components/global/HeaderCard';
import Loader from '../components/global/Loader';
import UserProfileCard from '../components/global/UserProfileCard';

const UsersDetail = () => {
  const [userId, setUserId] = useState(0);
  const [userObj, setUserObj] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    setUserId(userId);
    getUserInfo(userId);
  }, []);

  const getUserInfo = (userId) => {
    let body = {
      user_id: userId,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.UserDetails,
      body,
      (res) => {
        setUserObj(res.data);
        setLoader(false);
      },
      (error) => {
        console.log(error, ' error');
        setLoader(false);
      },
    );
  };

  return (
    <>
      <div>
        <UserProfileCard userObjPro={userObj} />
        <div className="">{loader && <Loader />}</div>
      </div>
    </>
  );
};

export default UsersDetail;
