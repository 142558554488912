import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FaWindowClose } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import CustomTooltip from "./CustomTooltip";
import Loader from "./Loader";
import MediaGallery from "./MediaGallery";


const MyAddress = () => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);

  const [mediaModal, setMediaModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [userId, setUserId] = useState(0);

  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    setUserId(userId);
    const url = "";
    setCurrentUrl(url);
    getAllUsersAddressWithPagination(url, userId);
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllUsersAddressWithPagination(url, userId);
  };

  const getAllUsersAddressWithPagination = (url, userId) => {
    let body = {
      user_id: userId,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.AllUserAddresses + url,
      body,
      (res) => {
        console.log(res, "user Wishes");
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">My Address</h5>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Title	</th>
                <th>Postal code</th>
                <th>City</th>
                <th>State</th>
                <th>country</th>
                <th>Address</th>
                <th>default Address</th>
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>

                    <td className="position-relative">
                      <p className="mb-0 ">{item?.title}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.postal_code}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.city}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">{item?.state}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.country}</p>
                    </td>


                    <td>
                      <CustomTooltip descriptionPro={item?.address} />
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">
                        {item?.is_default == 1 ? "Yes" : "No"}
                      </p>
                    </td>

                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
      <Modal show={mediaModal}>
        <button className="close-btn" onClick={() => setMediaModal(false)}>
          {<FaWindowClose className="close-icon" />}
        </button>
        <MediaGallery selectedItemPro={selectedItem} />
      </Modal>
    </>
  );
};

export default MyAddress;
