import React, { useState } from "react";
import Slider from "react-slick";
import HtmlVideo from "../forms/HtmlVideo";
import HtmlImage from "../forms/HtmlImage";
import { BASE_IMG_URL } from "../../Util/EndPoint";

const MediaGallery = ({ selectedItemPro }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div>
        <Slider {...settings}>
          {selectedItemPro.media.length > 0 &&
            selectedItemPro.media.map((item, index) => (
              <div className="inner-slide" key={index}>
                {item.file_type == "image" && (
                  <HtmlImage urlPro={BASE_IMG_URL + item.filename} />
                )}
                {item.file_type == "video" && (
                  <HtmlVideo
                    urlPro={BASE_IMG_URL + item.filename}
                    urlThumbnailPro={BASE_IMG_URL + item.thumbnail}
                  />
                )}
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
};

export default MediaGallery;
