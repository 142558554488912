import moment from "moment";
import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import UsersOrderChart from '../charts/UsersOrderChart';


const UserOverView = ({ userObjPro }) => {
  return (


    <div className='mt-4'>

      <Row>
        <Col md="6 border">
          {Object.keys(userObjPro).length > 0 && (
            <UsersOrderChart userObjPro={userObjPro} />
          )}
        </Col>
        <Col md="6">
          <h4 className='user-info-heading bg-primary'>User Info</h4>

          <Table striped bordered hover className='user-detail-info-table'>

            <tbody>
              <tr>
                <th>Account Created</th>
                <td>{moment(userObjPro?.created_at).format("LL")}</td>
              </tr>

            </tbody>
          </Table>
        </Col>
      </Row>


    </div>
  )
}

export default UserOverView