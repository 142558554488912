import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FaEyeDropper } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { apiPost } from '../Util/ApiRequest';
import UserImg from '../assets/images/user.jpg';
import Loader from '../components/global/Loader';

import { BASE_IMG_URL, ENDPOINTS } from '../Util/EndPoint';
const Vouchers = () => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [userId, setUserId] = useState(0);

  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    setUserId(userId);
    const url = "";
    setCurrentUrl(url);
    getAllVouchersPagination(url, userId);
  }, []);

  const handleShowUsers = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllVouchersPagination(url, userId);
  };

  const getAllVouchersPagination = (url, userId) => {
    let body = {
      // user_id: userId,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.VoucherPaginated + url,
      body,
      (res) => {
        console.log(res, "user Wishes");
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto">Vouchers</h5>
          <Button
            variant="secondary"
            onClick={() => {
              window.location.href = `/admin/vouchers-create`;
            }}
          >
            create vouchers
          </Button>
        </div>
        <div className="table-responsive table-customs">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Code</th>
                <th>Max used</th>
                <th>Start at</th>
                <th>Expire at</th>
                <th>Discount type</th>
                <th>Discount value</th>
                <th>Voucher for</th>
                <th>Active</th>
                <th>Created at</th>
                <th>Users</th>
                <th>Detail</th>
                <th>update</th>
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.code}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.max_used}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">
                        {moment(item?.start_at).format("LL")}
                      </p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">
                        {moment(item?.expire_at).format("LL")}
                      </p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">{item?.dis_type}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">{item?.dis_value}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">{item?.voucher_for}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">
                        {item?.is_active == 1 ? "Yes" : "No"}
                      </p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0">
                        {moment(item?.created_at).format("LL")}
                      </p>
                    </td>
                    <td className="position-relative">
                      {item.voucher_for == "user" && (
                        <button
                          onClick={() => handleShowUsers(item)}
                          className="btn btn-primary"
                        >
                          show
                        </button>
                      )}
                      {item.voucher_for != "user" && <p>-</p>}
                    </td>
                    <td>
                      <a
                        href="/admin/vouchers-create"
                        className="btn btn-secondary"
                      >
                        Details
                      </a>
                    </td>

                    <td className="position-relative">
                      <button
                        className="no-btn-structure"
                        onClick={() => {
                          window.location.href = `/admin/vouchers-create`;
                        }}
                      >
                        <span className="text-info">
                          <FaEyeDropper />
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {selectedItem?.users_voucher?.length > 0 &&
              selectedItem?.users_voucher.map((item, index) => (
                <ListGroupItem key={index}>
                  <div className="d-flex gap-2">
                    <img
                      src={
                        item?.user?.image
                          ? BASE_IMG_URL + item?.user?.image
                          : UserImg
                      }
                      className="user-img-table"
                    />
                    <h5 className="my-auto">{item?.user?.email}</h5>
                  </div>
                </ListGroupItem>
              ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Vouchers;
