import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { apiGetAuth, apiPost } from "../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../Util/EndPoint";
import UserImg from "../assets/images/user.jpg";
import Loader from "../components/global/Loader";
import SnackBar from "../components/global/SnackBar";


const Stocks = () => {

  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });



  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);

  const [items, setItem] = useState([]);
  const [products, setProducts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [userId, setUserId] = useState(0);

  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    setUserId(userId);
    const url = "";
    setCurrentUrl(url);
    getAllStockPagination(url);
    getAllProducts();
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllStockPagination(url);
  };

  const getAllProducts = () => {
    apiGetAuth(
      ENDPOINTS.AllProductDropdownStock,
      (res) => {
        setProducts(res)
      },
      (error) => {
        console.log(error, "getAllProducts")
      }
    );

  }


  const submitFormData = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    const form = document.getElementById("stock-form");

    // Reset the form
    form.reset();
    setLoader(true);
    event.preventDefault();

    setLoader(true);
    apiPost(
      ENDPOINTS.AddStock,
      body,
      (res) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        getAllStockPagination(currentUrl)
        form.reset();
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  const getAllStockPagination = (url) => {
    let body = {
      // user_id: userId,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.StockPaginated + url,
      body,
      (res) => {
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div>
          <form id="stock-form" className="row" onSubmit={submitFormData}>
            <div className="col-md-4 border border-primary p-4 mb-4">

              <div>
                <h3>Add stock</h3>
                <label className="mb-2 d-block">Product</label>
                <Select
                  name="product_id"
                  formatOptionLabel={(option) => (
                    <div>
                      {option.image ? (
                        <img
                          className="dropdown-image"
                          src={BASE_IMG_URL + option?.image}
                        />
                      ) : (
                        <img className="dropdown-image" src={UserImg} />
                      )}
                      <span>{option.label}</span>
                    </div>
                  )}
                  // onChange={handleOnChangeSelect}
                  options={products}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">Quantity</label>
                <input
                  className="form-control"
                  type="text"
                  name="qty"
                  required
                />
              </div>

              <div className="mt-3">
                <label className="mb-2">Purchase date</label>
                <input
                  className="form-control"
                  type="date"
                  name="date"
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary mt-3 d-block w-100">Save</button>


            </div>

          </form>
        </div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto">Stock</h5>
        </div>
        <div className="table-responsive table-customs">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>price</th>
                <th>purchase_date</th>
                <th>qty</th>
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index} className="">
                    <td className="d-flex gap-2">
                      <img
                        src={
                          item?.products?.image
                            ? BASE_IMG_URL + item?.products?.image
                            : UserImg
                        }
                        className="user-img-table"
                      />
                      <p className="my-auto">{item?.products?.name}</p>
                    </td>

                    <td>{item?.products?.price}</td>
                    <td>{moment(item?.purchase_date).format("LL")}</td>
                    <td>{item?.qty}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <SnackBar
        closeSnackPro={() => setSnackInfo({ snackStatus: false })}
        snackInfoPro={snackInfo}
      />
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default Stocks;
