import BootstrapSwitchButton from "bootstrap-switch-button-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { FaCheck, FaPencilAlt } from "react-icons/fa";
import Select from "react-select";
import { apiGetAuth, apiPost } from "../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../Util/EndPoint";
import UserImg from "../assets/images/user.jpg";
import UpdateProducts from "../components/forms/UpdateProducts";
import CustomTooltip from "../components/global/CustomTooltip";
import Loader from "../components/global/Loader";
import SnackBar from "../components/global/SnackBar";

import { AiOutlineDelete } from "react-icons/ai";

const ProductDetail = ({ }) => {
  const [productSideline, setProductSideline] = useState([]);
  const [defaultSideline, setDefaultSideline] = useState([]);
  const [productAttributesAll, setProductAttributesAll] = useState([]);
  const [items, setItems] = useState([]);
  const [sidelineId, setSidelineId] = useState([]);
  const [sideLineModal, setSideLineModal] = useState(false);
  const [attributeModal, setAttributeModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [sidelineName, setSidelineName] = useState("");
  const [variantState, setVariantState] = useState(0);
  const [productId, setProductId] = useState(0);
  const [productObj, setProductObj] = useState({});
  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });

  useEffect(() => {
    let productIds = /[^/]*$/.exec(window.location.href)[0];
    setProductId(productIds);
    productInfo(productIds);
    getProductAttribute(productIds);
  }, []);

  // product functions start
  const productInfo = (productIds) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.ProductDetail + productIds,
      (res) => {
        setProductObj(res);
        const transformedData = res?.product_sidelines.map((item) => ({
          value: item.sideline.id,
          label: item.sideline.name,
          isDisabled: true,
        }));
        getProductSideline(transformedData);
        setDefaultSideline(transformedData);
        setLoader(false);
      },
      (error) => {
        console.log(error, " error");
        setLoader(false);
      }
    );
  };

  const activeDeActive = (item) => {
    setLoader(true);
    apiPost(
      ENDPOINTS.StatusProduct,
      {
        product_id: item.id,
      },
      (success) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        productInfo(productId);
        setLoader(false);
      },
      (error) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
        setLoader(false);
      }
    );
  };
  // product functions start

  // attribute function start
  const getProductAttribute = (productId) => {
    apiPost(
      ENDPOINTS.AttributesAllDropDownDefault,
      { product_id: productId },
      (res) => {
        setItems(res.data);
      },
      (error) => {
        console.log("ProductSidelineAdd", error);
      }
    );
  };

  const deleteProductAttribute = (item) => {
    const body = {
      attribute_id: item.attribute_id,
      product_id: productId,
      attribute_value_id: item.attribute_value_id,
    };
    apiPost(
      ENDPOINTS.ProductAttributeDelete,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setLoader(false);
        productInfo(productId);
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  const submitProductAttributeUpdatePriceAndImage = (e) => {
    e.preventDefault();
    const body = new FormData(e.target);
    apiPost(
      ENDPOINTS.ProductVariantPriceUpdate,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setLoader(false);
        productInfo(productId);
        setVariantState(0);
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    );
  };

  const updateProductAttribute = (e) => {
    e.preventDefault();
    const body = {
      product_id: productId,
      product_attribute_ids: JSON.stringify(productAttributesAll),
    };
    apiPost(
      ENDPOINTS.ProductVariantUpdate,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setLoader(false);
        productInfo(productId);
        setAttributeModal(false);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        setAttributeModal(false);
      }
    );
  };

  const handleOnChangeSelectAttribute = (selectedOption, action) => {
    if (action.action === "remove-value") {
      const updatedArray = productAttributesAll.filter(
        (obj) => obj.value !== action.removedValue.value
      );
      setProductAttributesAll(updatedArray);
    }

    if (action.action === "select-option") {
      let addOption = action.option;
      setProductAttributesAll((prevAttributes) => [
        ...prevAttributes,
        {
          attribute_id: addOption.attribute_id,
          label: addOption.label,
          value: addOption.value,
          isDisabled: addOption.isDisabled,
        },
      ]);
    }
  };
  // attribute function start

  //sideline start
  const getProductSideline = (arraySideline) => {
    apiGetAuth(
      ENDPOINTS.ProductSidelines,
      (res) => {
        for (const iterator of res) {
          let obj = arraySideline.find((o) => o.value === iterator.value);
          iterator.isDisabled = obj ? true : false;
        }

        console.log(res, "res");
        setProductSideline(res);
      },
      (error) => {
        console.log("ProductSidelineAdd", error);
      }
    );
  };

  const deleteProductSideline = (item) => {
    const body = {
      product_sideline_id: item.id,
      product_id: productId,
    };
    apiPost(
      ENDPOINTS.ProductSidelineDelete,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setLoader(false);
        productInfo(productId);
        setLoader(false);
      },
      (error) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
        setLoader(false);
      }
    );
  };

  const submitProductSidelineIds = (e) => {
    e.preventDefault();
    const body = {
      sideline_ids: JSON.stringify(defaultSideline),
      product_id: productId,
    };
    apiPost(
      ENDPOINTS.ProductSidelineDetailUpdate,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setLoader(false);
        productInfo(productId);
        setSideLineModal(false);
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  const submitProductSidelinePrice = (e) => {
    e.preventDefault();
    const body = new FormData(e.target);
    apiPost(
      ENDPOINTS.ProductSidelinePriceUpdate,
      body,
      (res) => {
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setLoader(false);
        productInfo(productId);
        setSidelineName("");
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  const handleOnchangeProductSideline = (items) => {
    setSidelineId([]);
    setDefaultSideline(items);
  };
  //sideline end

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            {/* onClick={() => SelectItem(item)} */}
            <h4 className="user-info-heading bg-primary  d-flex justify-content-between">
              Product Info
              <button
                className="btn text-white border"
                onClick={() => setShowUpdateModal(true)}
              >
                <FaPencilAlt />
              </button>
            </h4>

            <Table striped bordered hover className="user-detail-info-table table-customs">
              <tbody>
                <tr>
                  <th>Image</th>
                  <td>
                    <img
                      src={
                        productObj?.image
                          ? BASE_IMG_URL + productObj?.image
                          : UserImg
                      }
                      className="user-img-table"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Account Created</th>
                  <td>{moment(productObj?.created_at).format("LL")}</td>
                </tr>

                <tr>
                  <th>Name</th>
                  <td>{productObj?.name}</td>
                </tr>
                <tr>
                  <th>price</th>
                  <td>{productObj?.price}</td>
                </tr>
                <tr>
                  <th>cook_type</th>
                  <td>{productObj?.cook_type}</td>
                </tr>
                <tr>
                  <th>description</th>
                  <td>
                    <CustomTooltip descriptionPro={productObj?.description} />
                  </td>
                </tr>

                <tr>
                  <th>Frozen</th>
                  <td>{productObj?.frozen == 1 ? "Yes" : "No"}</td>
                </tr>

                <tr>
                  <th>Sideline</th>
                  <td>{productObj?.is_sideline == 1 ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Active</th>
                  <td>
                    <BootstrapSwitchButton
                      size="sm"
                      checked={productObj?.is_active == 1 ? true : false}
                      onlabel="Active"
                      offlabel="De-active"
                      onChange={() => activeDeActive(productObj)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-6">
            <div className="mb-4">
              <h4 className="user-info-heading bg-primary  d-flex justify-content-between">
                Product Sideline{" "}
                <button
                  className="btn text-white border"
                  onClick={() => setSideLineModal(true)}
                >
                  <FaPencilAlt />
                </button>
              </h4>
              {productObj?.product_sidelines?.length > 0 &&
                productObj.product_sidelines.map((item, index) => (
                  <form
                    className="mt-3"
                    key={index}
                    onSubmit={submitProductSidelinePrice}
                  >
                    <div>
                      <label className=" d-block mb-2">
                        {item?.sideline?.name}
                      </label>
                      <div className="d-flex gap-2">
                        <input
                          type="number"
                          name="price"
                          className="form-control"
                          defaultValue={item.price}
                          disabled={
                            sidelineName == item?.sideline?.name ? false : true
                          }
                        />

                        <input
                          type="hidden"
                          name="product_sideline_id"
                          value={item.id}
                        />

                        {sidelineName != item?.sideline?.name && (
                          <button
                            className="price-icons"
                            onClick={() =>
                              setSidelineName(item?.sideline?.name)
                            }
                          >
                            <FaPencilAlt />
                          </button>
                        )}
                        {sidelineName == item?.sideline?.name && (
                          <button className="price-icons" type="submit">
                            <FaCheck />
                          </button>
                        )}

                        <button
                          className="price-icons"
                          type="button"
                          onClick={() => deleteProductSideline(item?.sideline)}
                        >
                          <AiOutlineDelete />
                        </button>
                      </div>
                    </div>
                  </form>
                ))}
            </div>
            <div className="mb-4">
              <h4 className="user-info-heading bg-primary  d-flex justify-content-between">
                Product variant
                <button
                  className="btn text-white border"
                  onClick={() => setAttributeModal(true)}
                >
                  <FaPencilAlt />
                </button>
              </h4>
              {productObj?.variant?.length > 0 &&
                productObj.variant.map((item, index) => (
                  <form
                    className="mt-3"
                    key={index}
                    onSubmit={submitProductAttributeUpdatePriceAndImage}
                  >
                    <div>
                      <label className=" d-block mb-2">
                        {item?.attribute?.name} {"("}
                        {item?.attribute_value?.value}
                        {")"}
                      </label>
                      <div className="d-flex grid-3">
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={item.price}
                          disabled={variantState == item?.id ? false : true}
                          name="price"
                        />
                        <input
                          type="file"
                          className="form-control product_variant-aal"
                          name="image"
                          disabled={variantState == item?.id ? false : true}
                        />

                        <input
                          type="hidden"
                          name="product_variant_id"
                          value={item?.id}
                        />

                        <img
                          src={
                            item?.image ? BASE_IMG_URL + item?.image : UserImg
                          }
                          className="user-img-table"
                        />

                        {variantState != item?.id && (
                          <button
                            className="price-icons"
                            onClick={() => setVariantState(item?.id)}
                          >
                            <FaPencilAlt />
                          </button>
                        )}
                        {variantState == item?.id && (
                          <button className="price-icons" type="submit">
                            <FaCheck />
                          </button>
                        )}

                        <button
                          className="price-icons"
                          type="button"
                          onClick={() => deleteProductAttribute(item)}
                        >
                          <AiOutlineDelete />
                        </button>
                      </div>
                    </div>
                  </form>
                ))}
            </div>
          </div>
        </div>
      </div>

      <UpdateProducts
        showUpdateModalPro={showUpdateModal}
        hideUpdateModalPro={() => setShowUpdateModal(false)}
        selectedItemPro={productObj}
        refreshDataPro={() => productInfo(productObj.id)}
      />

      <SnackBar
        closeSnackPro={() => setSnackInfo({ snackStatus: false })}
        snackInfoPro={snackInfo}
      />
      <div className="">{loader && <Loader />}</div>

      {/* product sideline updates */}
      <Modal show={sideLineModal} onHide={() => setSideLineModal(false)}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Product sideline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitProductSidelineIds}>
            <div className="">
              <Select
                onChange={handleOnchangeProductSideline}
                isMulti
                options={productSideline}
                value={defaultSideline}
                isOptionDisabled={(option) => option.isDisabled}
              />
            </div>
            <button className="btn btn-primary mt-3">Update Sideline</button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={attributeModal} onHide={() => setAttributeModal(false)}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Product attributes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-primary border shadow rounded p-4">
            <form onSubmit={updateProductAttribute}>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <div key={index} className="mb-2">
                    <label className=" d-block text-white">
                      Select {item?.name}
                    </label>
                    <span className="font-10 mb-2 text-danger">
                      select {item?.name} if product has
                    </span>
                    <Select
                      onChange={handleOnChangeSelectAttribute}
                      isMulti
                      id={item.id}
                      name={item?.name}
                      defaultValue={item?.attribute_values_dropdown_default}
                      options={item?.attribute_values_dropdown}
                      isOptionDisabled={(option) => option.isDisabled}
                    />
                  </div>
                ))}
              <button className="btn btn-danger mt-3" type="submit">
                Update Attributes
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductDetail;
