import React, { useEffect, useState } from "react";
import Select from "react-select";
import { apiGetAuth, apiPost } from "../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../Util/EndPoint";
import UserImg from "../assets/images/user.jpg";
import Loader from "../components/global/Loader";
import SnackBar from "../components/global/SnackBar";


const RiderStock = () => {
  const [loader, setLoader] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });




  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts();
  }, []);


  const getAllProducts = () => {
    apiGetAuth(
      ENDPOINTS.AllProductDropdownStock,
      (res) => {
        setProducts(res)
      },
      (error) => {
        console.log(error, "getAllProducts")
      }
    );
  }


  const submitFormData = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    const form = document.getElementById("stock-form");
    form.reset();
    setLoader(true);
    event.preventDefault();
    setLoader(true);
    apiPost(
      ENDPOINTS.AddStock,
      body,
      (res) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        form.reset();
      },
      (error) => {
        setLoader(false);
        setSnackInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };



  return (
    <>
      <div>
        <div>
          <form id="stock-form" className="row" onSubmit={submitFormData}>
            <div className="col-md-4 border border-primary p-4 mb-4">

              <div>
                <h3>Product assign to rider</h3>
                <label className="mb-2 d-block">Product</label>
                <Select
                  name="product_id"
                  formatOptionLabel={(option) => (
                    <div>
                      {option.image ? (
                        <img
                          className="dropdown-image"
                          src={BASE_IMG_URL + option?.image}
                        />
                      ) : (
                        <img className="dropdown-image" src={UserImg} />
                      )}
                      <span>{option.label} quantity : {option.current_qty}</span>
                    </div>
                  )}
                  options={products}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">Quantity</label>
                <input
                  className="form-control"
                  type="text"
                  name="qty"
                  required
                />
              </div>



              <button type="submit" className="btn btn-primary mt-3 d-block w-100">Save</button>


            </div>

          </form>
        </div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto">Stock</h5>
        </div>

      </div>
      <SnackBar
        closeSnackPro={() => setSnackInfo({ snackStatus: false })}
        snackInfoPro={snackInfo}
      />
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default RiderStock;
