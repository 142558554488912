import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import Loader from "../global/Loader";

const CreateAttributes = ({ showAddPro, hideAddPro, refreshDataPro }) => {
  const [loader, setLoader] = useState(false);

  const [formValue, setFormValue] = useState({
    name: "",
    attributes_value: "",
  });

  const handleSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    var sizesArray = formValue.attributes_value
      .split("|")
      .map((item) => item.replace("|", ""));

    const body = {
      name: formValue.name,
      attributes_value: sizesArray,
    };

    apiPost(
      ENDPOINTS.CreateAttributes,
      body,
      (onSuccess) => {
        hideAddPro();
        refreshDataPro();
        setLoader(false);
      },
      (onFailure) => {
        hideAddPro();
        refreshDataPro();
        setLoader(false);
      }
    );
  };
  return (
    <>
      <Modal show={showAddPro} onHide={() => hideAddPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Create Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label className="mb-2">Name</label>
            <input
              className="form-control mb-4"
              name="name"
              type="text"
              required
              onChange={(e) =>
                setFormValue({ ...formValue, name: e.target.value })
              }
            />
            <label className="mb-2">Attributes values</label>
            <textarea
              className="form-control mb-4"
              name="attributes_value"
              type="text"
              required
              onChange={(e) =>
                setFormValue({ ...formValue, attributes_value: e.target.value })
              }
            ></textarea>

            <input className="btn btn-secondary" type="submit" />
          </form>
        </Modal.Body>
      </Modal>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default CreateAttributes;
