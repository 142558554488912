import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { apiGetAuth, apiPost } from "../Util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../Util/EndPoint";
import UserImg from "../assets/images/user.jpg";
import CreateRyder from "../components/forms/CreateRyder";
import Loader from "../components/global/Loader";

const Riders = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [userSearch, setUserSearch] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);

  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllRidersWithPagination(url);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllRidersWithPagination(currentUrl);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [userSearch]);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllRidersWithPagination(url);
  };

  const searchUsersByText = (name) => {
    setUserSearch(name);
    if (currentUrl) {
      setCurrentUrl(currentUrl + "&search_user=" + name);
      getAllRidersWithPagination(currentUrl + "&search_user=" + name);
    }
    setCurrentUrl("?search_user=" + name);
    getAllRidersWithPagination("?search_user=" + name);
    setTimeout();
  };

  const getAllRidersWithPagination = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.AllRidersWithPagination + url,
      (res) => {
        setLoader(false);
        setItem(res?.results);
        setTotalPage(res?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  const getUserDetailsByAdmin = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.UserDetailsByAdmin + url,
      (res) => {
        setLoader(false);
        setItem(res?.results);
        setTotalPage(res?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };
  const ActiveDeActiveUsers = (id) => {
    const body = {
      user_id: id,
    };

    apiPost(
      ENDPOINTS.UserActiveDeActiveByAdmin,
      body,
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <div>
        <div className="user-search mb-3">
          <label className="my-auto me-2">Riders Search</label>
          <Form.Control
            type="search"
            placeholder="Riders Search"
            className="me-2 users-search"
            aria-label="Search"
            value={userSearch}
            onChange={(e) => searchUsersByText(e.target.value)}
          />
        </div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto">Riders</h5>
          <Button variant="secondary" onClick={() => setShowAddModal(true)}>
            Create Rider
          </Button>
        </div>
        <div className="table-responsive table-customs">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>User Details</th>
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={item?.image ? BASE_IMG_URL + item?.image : UserImg}
                        className="user-img-table"
                      />
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.full_name}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.email}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.mobile}</p>
                    </td>

                    <td>
                      <BootstrapSwitchButton
                        size="sm"
                        checked={item?.is_blocked == 1 ? false : true}
                        onlabel="Active"
                        offlabel="De-active"
                        onChange={() => ActiveDeActiveUsers(item?.id)}
                      />
                    </td>
                    <td>
                      <a
                        href={`user-detail/${item.id}`}
                        className="btn btn-secondary"
                      >
                        User Details
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />

        <CreateRyder
          showAddPro={showAddModal}
          hideAddPro={() => setShowAddModal(false)}
          refreshDataPro={() => getAllRidersWithPagination(currentUrl)}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default Riders;
