import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import Loader from "../global/Loader";

const CreateMealsType = ({ showAddPro, hideAddPro, refreshDataPro }) => {
  const [loader, setLoader] = useState(false);

  const handleSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    const body = new FormData(event.target);

    apiPost(
      ENDPOINTS.CreateMealsType,
      body,
      (onSuccess) => {
        hideAddPro();
        refreshDataPro();
        setLoader(false);

      },
      (onFailure) => {
        hideAddPro();
        refreshDataPro();
        setLoader(false);

      }
    );
  };
  return (
    <>
      <Modal show={showAddPro} onHide={() => hideAddPro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Create Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label className="mb-2">Name</label>
            <input
              className="form-control mb-4"
              name="name"
              type="text"
              required
            />
            <label className="mb-2">Description</label>
            <textarea
              className="form-control mb-4"
              name="description"
              type="text"
              required
            ></textarea>

            <label className="mb-2">image:</label>
            <input
              className="form-control mb-4"
              type="file"
              required
              name="image"
            />
            <input className="btn btn-secondary" type="submit" />
          </form>
        </Modal.Body>
      </Modal>
      <div className="">{loader && <Loader />}</div>

    </>
  );
};

export default CreateMealsType;
